import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const CollapsiblePanel = ({
  title = 'Panel Title',
  children,
  defaultOpen = false,
  className = '',
  headerClassName = '',
  contentClassName = '',
  contentContainerClassName = '',
  iconOpen = null,
  iconClosed = null,
  showArrow = true,
  transitionDuration = 300,
  maxHeight = 'none', // Can be 'none' or specific value like '300px'
  scrollable = true,
  scrollbarStyle = 'thin' // 'thin', 'auto', or 'none'
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [contentHeight, setContentHeight] = useState('0px');
  const contentRef = useRef(null);
  const innerContentRef = useRef(null);

  // Calculate height whenever isOpen or children change
  useEffect(() => {
    if (contentRef.current && innerContentRef.current) {
      if (isOpen) {
        const height = maxHeight === 'none' 
          ? `${innerContentRef.current.scrollHeight}px`
          : `min(${innerContentRef.current.scrollHeight}px, ${maxHeight})`;
        setContentHeight(height);
      } else {
        setContentHeight('0px');
      }
    }
  }, [isOpen, children, maxHeight]);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      if (isOpen && contentRef.current && innerContentRef.current) {
        const height = maxHeight === 'none' 
          ? `${innerContentRef.current.scrollHeight}px`
          : `min(${innerContentRef.current.scrollHeight}px, ${maxHeight})`;
        setContentHeight(height);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isOpen, maxHeight]);

  // Default arrow icon
  const defaultArrow = (
    <svg
      className={`w-5 h-5 text-gray-600 transform transition-transform duration-${transitionDuration} ${
        isOpen ? 'rotate-180' : ''
      }`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
    </svg>
  );

  // Scrollbar classes based on preference
  const getScrollbarClasses = () => {
    switch (scrollbarStyle) {
      case 'thin':
        return 'scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100';
      case 'auto':
        return 'scrollbar-auto';
      case 'none':
        return 'scrollbar-none';
      default:
        return 'scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100';
    }
  };

  return (
    <div className={`w-full overflow-hidden rounded-lg shadow-sm border border-gray-200 ${className}`}>
      <button
        className={`flex items-center justify-between w-full p-4 text-left bg-gray-50 hover:bg-gray-100 transition-colors duration-200 focus:outline-none ${headerClassName}`}
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}
      >
        <span className="font-medium text-gray-800 truncate">{title}</span>
        {showArrow && (
          <span className="ml-2 flex-shrink-0">
            {iconOpen && iconClosed ? (isOpen ? iconOpen : iconClosed) : defaultArrow}
          </span>
        )}
      </button>
      
      <div
        ref={contentRef}
        className={`overflow-hidden transition-all duration-${transitionDuration} ease-in-out bg-white ${
          isOpen ? 'border-t border-gray-200' : ''
        } ${contentClassName}`}
        aria-hidden={!isOpen}
        style={{
          maxHeight: contentHeight,
          transitionDuration: `${transitionDuration}ms`,
          overflowY: scrollable && isOpen && maxHeight !== 'none' ? 'auto' : 'hidden'
        }}
      >
        <div 
          ref={innerContentRef}
          className={`p-4 ${scrollable && maxHeight !== 'none' ? getScrollbarClasses() : ''} ${contentContainerClassName}`}
          style={{
            overflowY: scrollable && isOpen && maxHeight !== 'none' ? 'auto' : 'visible'
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

CollapsiblePanel.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node.isRequired,
  defaultOpen: PropTypes.bool,
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  contentContainerClassName: PropTypes.string,
  iconOpen: PropTypes.node,
  iconClosed: PropTypes.node,
  showArrow: PropTypes.bool,
  transitionDuration: PropTypes.number,
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  scrollable: PropTypes.bool,
  scrollbarStyle: PropTypes.oneOf(['thin', 'auto', 'none'])
};

CollapsiblePanel.defaultProps = {
  scrollable: true,
  scrollbarStyle: 'thin'
};

export default CollapsiblePanel;