import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

const baseUrl = process.env.REACT_APP_BASE_API_URL;

const BlogPostForm = ({ onSubmit, initialLoading = false }) => {
  const [formData, setFormData] = useState({
    title: '',
    author: '',
    link: '',
    content: ''
  });
  const [loading, setLoading] = useState(initialLoading);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const resetForm = () => {
    setFormData({
      title: '',
      author: '',
      link: '',
      content: ''
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      const response = await axios.post(`${baseUrl}/blogposts`, formData, {
        headers: {
          'Content-Type': 'application/json'
        }   
      });
      
      if (response.status >= 200 && response.status < 300) {
        toast.success('News Item created successfully!');
        resetForm();
        setFormSubmitted(prev => !prev);
        if (onSubmit) onSubmit(response.data);
      }
    } catch (error) {
      toast.error('Failed to create News Item. Please try again.');
      console.error('Error creating blog post:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="py-4 px-9">
      <div className="mb-5">
        <label htmlFor="title" className="mb-3 block text-base font-medium text-[#07074D]">
          Blog Post Title:
        </label>
        <input
          type="text"
          name="title"
          id="title"
          placeholder="Enter blog post title"
          value={formData.title}
          onChange={handleChange}
          className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
          required
        />
      </div>

      <div className="mb-5">
        <label htmlFor="author" className="mb-3 block text-base font-medium text-[#07074D]">
          Author (optional):
        </label>
        <input
          type="text"
          name="author"
          id="author"
          placeholder="Enter author name"
          value={formData.author}
          onChange={handleChange}
          className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
        />
      </div>

      <div className="mb-5">
        <label htmlFor="link" className="mb-3 block text-base font-medium text-[#07074D]">
          Link:
        </label>
        <input
          type="url"
          name="link"
          id="link"
          placeholder="https://example.com"
          value={formData.link}
          onChange={handleChange}
          className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
          required
        />
      </div>

      <div className="mb-5">
        <label htmlFor="content" className="mb-3 block text-base font-medium text-[#07074D]">
          Content:
        </label>
        <textarea
          name="content"
          id="content"
          rows="6"
          placeholder="Write your blog post content here..."
          value={formData.content}
          onChange={handleChange}
          className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
          required
        ></textarea>
      </div>

      <div>
        <button
          type="submit"
          disabled={loading}
          className={`hover:shadow-form w-full rounded-md bg-emerald-600 py-3 px-8 text-center text-base font-semibold text-white outline-none ${
            loading ? 'cursor-not-allowed opacity-50' : ''
          }`}
        >
          {loading ? (
            <div className="flex items-center justify-center">
              <svg
                className="animate-spin h-5 w-5 mr-2 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                ></path>
              </svg>
              Saving...
            </div>
          ) : (
            'Create Blog Post'
          )}
        </button>
      </div>
    </form>
  );
};

export default BlogPostForm;