import { FaChalkboardTeacher, FaChartLine, FaLightbulb, FaUserGraduate, FaHandsHelping, FaBookReader } from 'react-icons/fa';
import edu2 from "../../src/images/absedu2.png";
import edu3 from "../../src/images/absedu3.png";
import edu4 from "../../src/images/absedu4.png";
import ImageComponent from "./ImageComponent";

const Features = () => {
  const featuresLeft = [
    {
      icon: <FaChalkboardTeacher className="text-3xl text-blue-600" />,
      title: "Advanced Mentorship Solutions",
      description: "We provide extensive training and support to teachers in participating schools, empowering them with the tools and methodologies they need to effectively implement TaRL and engage learners."
    },
    {
      icon: <FaUserGraduate className="text-3xl text-blue-600" />,
      title: "Making a Difference",
      description: "Since launching our pilot projects in five underserved schools, we've seen impressive improvements in literacy and numeracy skills of participating students."
    },
    {
      icon: <FaHandsHelping className="text-3xl text-blue-600" />,
      title: "Trained Personnel Support",
      description: "Our trained experts ensure smooth teaching and ongoing guidance."
    }
  ];

  const featuresRight = [
    {
      icon: <FaChartLine className="text-3xl text-blue-600" />,
      title: "Powerful Analytics Capabilities",
      description: "Extract valuable insights from data with comprehensive visualizations. Monitor KPIs and track trends."
    },
    {
      icon: <FaLightbulb className="text-3xl text-blue-600" />,
      title: "Personalized Solutions",
      description: "Tailored software solutions to address unique learner needs. Achieve sustainable growth."
    },
    {
      icon: <FaBookReader className="text-3xl text-blue-600" />,
      title: "Continuous Innovation",
      description: "Empower struggling learners to overcome challenges and excel in their studies."
    }
  ];

  return (
    <div className="bg-[#e0e0e0] font-poppins py-20 px-6 lg:py-[10px]">
      {/* Hero Cards Section */}
      <div className="container mx-auto grid md:grid-cols-2 gap-8 mb-16">
        <div className="bg-white rounded-xl shadow-lg overflow-hidden flex flex-col md:flex-row h-full transition-transform duration-300 hover:scale-[1.02]">
          <div className="md:w-1/3">
            <ImageComponent
              className="w-full h-64 md:h-full object-cover"
              src={edu3}
              alt="Teaching at the Right Level"
            />
          </div>
          <div className="p-6 md:w-2/3">
            <h3 className="text-2xl font-bold text-gray-900 mb-3">Teaching at the Right Level (TaRL)</h3>
            <p className="text-gray-600">
              At the heart of our work is the TaRL methodology, a proven, child-centered approach that helps students improve their foundational reading and math skills.
            </p>
          </div>
        </div>

        <div className="bg-white rounded-xl shadow-lg overflow-hidden flex flex-col md:flex-row-reverse h-full transition-transform duration-300 hover:scale-[1.02]">
          <div className="md:w-1/3">
            <ImageComponent
              className="w-full h-64 md:h-full object-cover"
              src={edu2}
              alt="Teaching Methodology"
            />
          </div>
          <div className="p-6 md:w-2/3">
            <h3 className="text-2xl font-bold text-gray-900 mb-3">Child-Centered Approach</h3>
            <p className="text-gray-600">
              This approach focuses on assessing each child's current level of understanding and providing targeted support to help them catch up and progress in their learning.
            </p>
          </div>
        </div>
      </div>

      {/* Main Features Section */}
      <div className="max-w-7xl mx-auto">
        {/* First Feature Block */}
        <div className="grid lg:grid-cols-2 gap-12 items-center mb-24">
          <div>
            <h2 className="text-3xl font-bold text-gray-900 mb-6">Literacy and Numeracy Program</h2>
            <p className="text-lg text-gray-600 mb-8">
              We partner with schools, teachers, and local education authorities to ensure our programs align with national standards and make a lasting impact.
            </p>
            
            <div className="space-y-8">
              {featuresLeft.map((feature, index) => (
                <div key={index} className="flex items-start">
                  <div className="flex-shrink-0 bg-blue-50 p-3 rounded-full">
                    {feature.icon}
                  </div>
                  <div className="ml-4">
                    <h3 className="text-xl font-semibold text-gray-900">{feature.title}</h3>
                    <p className="mt-2 text-gray-600">{feature.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          
          <div className="rounded-xl overflow-hidden shadow-lg">
            <ImageComponent
              src={edu4}
              alt="Literacy Program"
              className="w-full h-auto object-cover"
            />
          </div>
        </div>

        {/* Second Feature Block */}
        <div className="grid lg:grid-cols-2 gap-12 items-center">
          <div className="lg:order-2">
            <h2 className="text-3xl font-bold text-gray-900 mb-6">Transformable Decisions</h2>
            <p className="text-lg text-gray-600 mb-8">
              Transform the weakest learners into confident, capable individuals who can read English fluently and tackle Math problems with ease.
            </p>
            
            <div className="space-y-8">
              {featuresRight.map((feature, index) => (
                <div key={index} className="flex items-start">
                  <div className="flex-shrink-0 bg-blue-50 p-3 rounded-full">
                    {feature.icon}
                  </div>
                  <div className="ml-4">
                    <h3 className="text-xl font-semibold text-gray-900">{feature.title}</h3>
                    <p className="mt-2 text-gray-600">{feature.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          
          <div className="rounded-xl overflow-hidden shadow-lg lg:order-1">
            <ImageComponent
              src={edu2}
              alt="Transformation Process"
              className="w-full h-auto object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;