import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaFolderOpen } from 'react-icons/fa';
import { toast } from 'react-toastify';

const baseUrl = process.env.REACT_APP_BASE_API_URL;

const BlogPostTable = ({ formSubmitted }) => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlogPosts = async () => {
      try {
        const response = await axios.get(`${baseUrl}/blogposts`);
        setBlogPosts(response.data);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching blog posts: ", err);
        toast.error('Failed to load blog posts');
        setLoading(false);
      }
    };
    fetchBlogPosts();
  }, [formSubmitted]);

  const handleDelete = async (postId) => {
    const userConfirmed = window.confirm(
      'Are you sure you want to delete this blog post? This action cannot be undone.'
    );

    if (userConfirmed) {
      try {
        await axios.delete(`${baseUrl}/blogposts/${postId}`);
        setBlogPosts(prevPosts => prevPosts.filter(post => post.id !== postId));
        toast.success('Blog post deleted successfully');
      } catch (err) {
        console.error('Error deleting blog post:', err);
        toast.error('Failed to delete blog post');
      }
    }
  };

  return (
    <div className="bg-white container mx-auto p-4 rounded-lg">
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-blue-500"></div>
          <span className="ml-2 text-gray-500">Loading blog posts...</span>
        </div>
      ) : blogPosts.length === 0 ? (
        <div className="flex justify-center items-center flex-col h-64">
          <FaFolderOpen className="text-6xl text-gray-400 mb-4" />
          <span className="text-gray-500">No blog posts available</span>
        </div>
      ) : (
        <>
          {/* Desktop View */}
          <table className="table-auto w-full border-collapse border border-gray-300 hidden md:table">
            <thead>
              <tr>
                <th className="border border-gray-300 p-2 text-left">Title</th>
                <th className="border border-gray-300 p-2 text-left">Author</th>
                <th className="border border-gray-300 p-2 text-left">Link</th>
                <th className="border border-gray-300 p-2 text-left">Date</th>
                <th className="border border-gray-300 p-2 text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {blogPosts.map((post) => (
                <tr key={post.id}>
                  <td className="border border-gray-300 p-2">{post.title}</td>
                  <td className="border border-gray-300 p-2">{post.author || 'N/A'}</td>
                  <td className="border border-gray-300 p-2">
                    <a 
                      href={post.link} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:underline"
                    >
                      View Post
                    </a>
                  </td>
                  <td className="border border-gray-300 p-2">{post.date}</td>
                  <td className="border border-gray-300 p-2 text-center">
                    <button
                      onClick={() => handleDelete(post.id)}
                      className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-600 mr-2"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Mobile View */}
          <div className="block md:hidden">
            {blogPosts.map((post) => (
              <div key={post.id} className="border border-gray-300 mb-4 p-4 rounded-lg">
                <div className="flex flex-col space-y-3">
                  <div>
                    <span className="font-bold text-gray-700">Title:</span>
                    <p>{post.title}</p>
                  </div>
                  
                  <div>
                    <span className="font-bold text-gray-700">Author:</span>
                    <p>{post.author || 'N/A'}</p>
                  </div>
                  
                  <div>
                    <span className="font-bold text-gray-700">Link:</span>
                    <a 
                      href={post.link} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:underline block mt-1"
                    >
                      View Post
                    </a>
                  </div>
                  
                  <div>
                    <span className="font-bold text-gray-700">Date:</span>
                    <p>{post.date}</p>
                  </div>
                  
                  <div className="flex justify-end">
                    <button
                      onClick={() => handleDelete(post.id)}
                      className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-600"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default BlogPostTable;