import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import PagedWorkshopTable from './WorkshopTable';
import BlogPostForm from './BlogPostForm';
import BlogPostTable from './BlogPostTable';
import BlogPostList from './BlogPostList';
import Navbar from "../../Navigation/Navbar";
import 'react-toastify/dist/ReactToastify.css'
import CollapsiblePanel from '../../CallToAction/CollapsiblePanel.jsx';

const baseUrl = process.env.REACT_APP_BASE_API_URL;

const DashboardPage = () => {
  const [description, setDescription] = useState('');
  const [subject, setSubject] = useState('');
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false); // New loading state
  const [formSubmitted, setFormSubmitted] = useState(false); // New state to track form submission

  // Handle the file upload
  const handleFileUpload = (e) => {
    // Convert FileList to an array using Array.from
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles); // Update the state with the array of files
  };

  const removeFile = (index) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  // Reset the form after successful submission
  const resetForm = () => {
    setDescription('');  // Reset description
    setSubject('');
    setFiles([]);        // Reset files
    document.getElementById('file').value = null; // Reset file input
  };

  // Handle the form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    setLoading(true); // Set loading state to true

    // Create a FormData object to send the data as multipart/form-data
    const formData = new FormData();
    formData.append('description', description); // Append the description
    formData.append('subject', subject); // Append the Subject

    // Append each file in the list of files
    for (let i = 0; i < files.length; i++) {
      formData.append('workshopImages', files[i]); // Append each file to 'workshopImages'
    }

    try {
      const response = await axios.post(`${baseUrl}/workshops`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
        },
      });
      if (response.status >= 200 && response.status < 300) {
        // Show success toast when the response is successful (200 status code)
        toast.success('Workshop created successfully!');

        // Reset the form after successful submit
        resetForm();
        setFormSubmitted((prevState) => !prevState); // Toggle the state to trigger rerender
      }
    } catch (error) {
      toast.error('Failed to create Workshop. Please try again.');
    }finally {
      // Set loading to false when request completes
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-[#e0e0e0] p-6 space-y-6 ">
       
       <CollapsiblePanel 
          title="Workshop section" 
          defaultOpen={false}
          className="border-blue-200 mt-24"
          headerClassName="bg-blue-50 hover:bg-blue-100"
        >
          
        <form onSubmit={handleSubmit} className="py-4 px-9">
            <div className="mb-5">
              <label htmlFor="description" className="mb-3 block text-base font-medium text-[#07074D]">
                Workshop Description:
              </label>
              <input
                type="text"
                name="description"
                id="description"
                placeholder=""
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                required
              />
            </div>

            <div className="mb-5">
              <label htmlFor="description" className="mb-3 block text-base font-medium text-[#07074D]">
                Workshop Subject:
              </label>
              <input
                type="text"
                name="subject"
                id="subject"
                placeholder=""
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                required
              />
            </div>

            <div className="mb-6 pt-4">
              <label className="mb-5 block text-l font-semibold text-[#07074D]">
                Upload Images
              </label>

              <div className="mb-8">
                <input
                  type="file"
                  id="file"
                  name="file"
                  accept="image/*"
                  className="sr-only"
                  multiple
                  onChange={handleFileUpload}
                />
                <label
                  htmlFor="file"
                  className="relative flex min-h-[200px] items-center justify-center rounded-md border border-dashed border-[#e0e0e0] p-12 text-center cursor-pointer"
                >
                  <div>
                    <span className="mb-2 block text-xl font-semibold text-[#07074D]">
                      Drop images here
                    </span>
                    <span className="mb-2 block text-base font-medium text-[#6B7280]">
                      Or
                    </span>
                    <span className="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base font-medium text-[#07074D]">
                      Browse
                    </span>
                  </div>
                </label>
              </div>

              {files.map((file, index) => (
                <div key={index} className="mb-5 rounded-md bg-[#F5F7FB] py-4 px-8">
                  <div className="flex items-center justify-between">
                    <span className="truncate pr-3 text-base font-medium text-[#07074D]">
                      {file.name}
                    </span>
                    <button
                      type="button"
                      className="text-[#07074D]"
                      onClick={() => removeFile(index)}
                    >
                      <svg
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0.279337 0.279338C0.651787 -0.0931121 1.25565 -0.0931121 1.6281 0.279338L9.72066 8.3719C10.0931 8.74435 10.0931 9.34821 9.72066 9.72066C9.34821 10.0931 8.74435 10.0931 8.3719 9.72066L0.279337 1.6281C-0.0931125 1.25565 -0.0931125 0.651788 0.279337 0.279338Z"
                          fill="currentColor"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0.279337 9.72066C-0.0931125 9.34821 -0.0931125 8.74435 0.279337 8.3719L8.3719 0.279338C8.74435 -0.0931127 9.34821 -0.0931123 9.72066 0.279338C10.0931 0.651787 10.0931 1.25565 9.72066 1.6281L1.6281 9.72066C1.25565 10.0931 0.651787 10.0931 0.279337 9.72066Z"
                          fill="currentColor"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="relative mt-5 h-[6px] w-full rounded-lg bg-[#E2E5EF]">
                    <div
                      className="absolute left-0 h-full w-[75%] rounded-lg bg-[#6A64F1]"
                      style={{ width: `${file.progress}%` }}
                    ></div>
                  </div>
                </div>
              ))}
            </div>

            <div>
            <button
            type="submit"
            disabled={loading} // Disable button when loading
            className={`hover:shadow-form w-full rounded-md bg-emerald-600 py-3 px-8 text-center text-base font-semibold text-white outline-none ${
              loading ? 'cursor-not-allowed opacity-50' : ''
            }`}
          >
            {/* Display spinner if loading, else show "Save" */}
            {loading ? (
              <div className="flex items-center justify-center">
                <svg
                  className="animate-spin h-5 w-5 mr-2 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                  ></path>
                </svg>
                Saving...
              </div>
            ) : (
              'Save'
            )}
          </button>
            </div>
        </form>
      
        <PagedWorkshopTable formSubmitted={formSubmitted} />
      
       </CollapsiblePanel>

      <CollapsiblePanel 
        title="News section" 
        defaultOpen={false}
        className="border-blue-200"
        headerClassName="bg-blue-50 hover:bg-blue-100"
      >
          <BlogPostForm 
            onSubmit={(formData) => {
              // Handle form submission here
              console.log(formData);
            }} 
            loading={false} 
          />

          <BlogPostTable formSubmitted={formSubmitted} />
       </CollapsiblePanel>
      
      <Navbar />
    </div>
  );
};

export default DashboardPage;
