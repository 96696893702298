import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaCalendarAlt, FaUserAlt, FaExternalLinkAlt } from 'react-icons/fa';
import { motion } from 'framer-motion';

const BlogPostList = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogPosts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/blogposts`);
        setBlogPosts(response.data);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching blog posts:", err);
        setError('Failed to load blog posts');
        setLoading(false);
      }
    };
    fetchBlogPosts();
  }, []);

  return (
    <>
      <div className="flex justify-end ml-[30%] w-[70%] h-[2px] bg-gradient-to-r from-yellow-300 to-emerald-100"></div>
      <div className="py-20 px-6 lg:py-[50px] bg-[#e0e0e0]  font-poppins">
        <div className="container mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              Latest News & Articles
            </h2>
            <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
              Stay updated with our latest stories and insights
            </p>
          </div>

          {loading ? (
            <div className="space-y-8">
              {/* Loading Skeleton */}
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="bg-white rounded-xl shadow-md overflow-hidden"
              >
                <div className="p-6 space-y-4">
                  <div className="flex items-center space-x-4">
                    <div className="h-4 w-24 bg-gray-200 rounded animate-pulse"></div>
                  </div>
                  <div className="h-6 w-3/4 bg-gray-200 rounded animate-pulse"></div>
                  <div className="space-y-2">
                    <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
                    <div className="h-4 bg-gray-200 rounded animate-pulse w-5/6"></div>
                    <div className="h-4 bg-gray-200 rounded animate-pulse w-2/3"></div>
                  </div>
                  <div className="flex justify-between">
                    <div className="h-4 w-20 bg-gray-200 rounded animate-pulse"></div>
                    <div className="h-4 w-24 bg-gray-200 rounded animate-pulse"></div>
                  </div>
                </div>
              </motion.div>
            </div>
          ) : error ? (
            <div className="text-center py-10 text-red-500">
              {error}
            </div>
          ) : (
            <div className="space-y-8">
              {blogPosts.length > 0 ? (
                blogPosts.map((post) => (
                  <motion.div
                    key={post.id}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    whileHover={{ y: -5 }}
                    className="bg-white rounded-xl shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300"
                  >
                    <div className="p-6">
                      <div className="flex items-center mb-2">
                        <FaCalendarAlt className="text-gray-400 mr-2" />
                        <span className="text-sm text-gray-500">{post.date}</span>
                      </div>
                      
                      <h3 className="text-xl font-semibold text-gray-900 mb-3">
                        {post.title}
                      </h3>
                      
                      <p className="text-gray-600 mb-4">
                        {post.content}
                      </p>
                      
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <FaUserAlt className="text-gray-400 mr-2" />
                          <span className="text-sm text-gray-500">
                            {post.author || 'Anonymous'}
                          </span>
                        </div>
                        
                        <a
                          href={post.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="inline-flex items-center text-blue-600 hover:text-blue-800 transition-colors"
                        >
                          Read more <FaExternalLinkAlt className="ml-1 text-xs" />
                        </a>
                      </div>
                    </div>
                  </motion.div>
                ))
              ) : (
                <div className="text-center py-10">
                  <p className="text-gray-500">No blog posts available yet. Check back soon!</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BlogPostList;