
import React, { useEffect, useRef } from "react";
import {motion, useInView, useAnimation} from 'framer-motion';

const CallToAction = () => {
    const ref = useRef();
    const isInView = useInView(ref)
    const mainControlls = useAnimation(isInView);
    //use effect when page loads
    useEffect(()=> {
        if(isInView) {
            mainControlls.start("visible")
        }
    }, [mainControlls, isInView])
  return (
    <div>
      <>
        <section class="py-10 px-6 lg:py-[60px] bg-[#e0e0e0]  font-poppins">
          <div class="container mx-auto">
            <div class=" bg-emerald-600 relative z-10 sm:-mt-10 sm:-mb-10 overflow-x-hidden rounded py-12 px-8 md:p-[70px]">
              <div class="-mx-4 flex flex-wrap items-center">
                <motion.div
                  ref={ref}
                  variants={{
                    visible: { opacity: 1, x: 0 },
                    hidden: { opacity: 0, x: -75 },
                  }}
                  initial="hidden"
                  animate={mainControlls}
                  transition={{ duration: 0.4, delay: 0.2 }}
                  class="w-full px-4 lg:w-1/2"
                >
                  <span class="mb-2 text-base font-semibold text-white">
                    
                  </span>
                  <h2 class="mb-6 text-lg font-bold leading-tight text-white sm:mb-8 sm:text-[38px] lg:mb-0">
                    Join Us <br class="xs:block hidden" />
                    <span className="text-lg text-transparent bg-clip-text bg-gradient-to-r from-blue-300 to-emerald-300">
                    We cannot do this alone. We invite individuals, corporations, and organizations to support our mission through partnerships, donations, and volunteering. Your involvement will help us expand our reach and impact more students across Ghana. Together, we can create lasting change.
                    </span>
                    
                  </h2>
                </motion.div>
                <div class="w-full px-4 lg:w-1/2">
                  <div class="flex sm:flex-wrap sm:justify-end">
                    <motion.div
                      ref={ref}
                      variants={{
                        visible: { opacity: 1, x: 0 },
                        hidden: { opacity: 0, x: 75 },
                      }}
                      initial="hidden"
                      animate={mainControlls}
                      transition={{ duration: 0.4, delay: 0.2 }}
                    >
                      {" "}
                      <button
                        href="#"
                        class="my-1 mx-1 inline-block rounded bg-gradient-to-r from-emerald-500 via-lime-500 to-emerald-500   hover:from-orange-500 hover:via-pink-500 hover:to-yellow-500 py-4 px-6 text-base font-medium text-white transition  md:px-9 lg:px-6 xl:px-9"
                      >
                        <span className="block sm:hidden">DONATE</span>
                        <span className="hidden sm:block md:hidden">Contact Us</span>
                        <span className="hidden md:block lg:hidden">Get in Touch</span>
                        <span className="hidden lg:block">Contact Agent</span>
                      </button>
                    </motion.div>
                    <motion.div
                      ref={ref}
                      variants={{
                        visible: { opacity: 1, x: 0 },
                        hidden: { opacity: 0, x: 75 },
                      }}
                      initial="hidden"
                      animate={mainControlls}
                      transition={{ duration: 0.4, delay: 0.5 }}
                    >
                      {" "}
                      <button
                        href="#"
                        class="my-1 inline-block rounded bg-gradient-to-r from-emerald-500 via-lime-500 to-emerald-500   hover:from-orange-500 hover:via-pink-500 hover:to-yellow-500 py-4 px-6 text-base font-medium text-white transition  md:px-9 lg:px-6 xl:px-9"
                      >
                        VOLUNTEER
                      </button>
                    </motion.div>
                  </div>
                </div>
              </div>
              <div>
                <span class="absolute top-0 left-0 z-[-1]">
                  <svg
                    width="189"
                    height="162"
                    viewBox="0 0 189 162"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <ellipse
                      cx="16"
                      cy="-16.5"
                      rx="173"
                      ry="178.5"
                      transform="rotate(180 16 -16.5)"
                      fill="url(#paint0_linear)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear"
                        x1="-157"
                        y1="-107.754"
                        x2="98.5011"
                        y2="-106.425"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="white" stop-opacity="0.07" />
                        <stop offset="1" stop-color="white" stop-opacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                </span>
                <span class="absolute bottom-0 right-0 z-[-1]">
                  <svg
                    width="191"
                    height="208"
                    viewBox="0 0 191 208"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <ellipse
                      cx="173"
                      cy="178.5"
                      rx="173"
                      ry="178.5"
                      fill="url(#paint0_linear)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear"
                        x1="-3.27832e-05"
                        y1="87.2457"
                        x2="255.501"
                        y2="88.5747"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="white" stop-opacity="0.07" />
                        <stop offset="1" stop-color="white" stop-opacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                </span>
              </div>
            </div>
           
          </div>
        </section>
      </>
      
    </div>
  );
};

export default CallToAction;
